import * as React from "react";
import styled from "styled-components";

import { IconStore, NavItem } from "../../../components/atoms";
import { theme } from "../../../theme";
import { device } from "../../../utils";

interface IProps {
  className?: string;
  children?: React.ReactNode;
  adjustLink: string;
}

const AppStoreGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  width: 240px;

  @media ${device.tablet} {
    flex-direction: column;
    width: 120px;
  }
`;

const AppStoreNavItem = styled(NavItem)`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transition: all ${theme.transition.linear02};
    width: 100%;
    height: auto;
  }

  &:hover {
    svg {
      opacity: 0.9;
    }
  }
`;

const AppStore: React.FC<IProps> = (props) => {
  return (
    <AppStoreGroup className={props.className}>
      <AppStoreNavItem href={props.adjustLink} target="_blank">
        <IconStore type="ios" />
      </AppStoreNavItem>
      <AppStoreNavItem href={props.adjustLink} target="_blank">
        <IconStore type="android" />
      </AppStoreNavItem>
    </AppStoreGroup>
  );
};

export { AppStore };
