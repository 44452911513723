import { ApiError } from "../error-handler";
import { getHandler, postHandler } from "../request-handler";

interface IApiBookingTreatment {
  urn: string;
  treatment: {
    urn: string;
    name: string;
    duration: number;
    description: string;
  };
  price: number;
}

export interface IApiBooking {
  urn: string;
  timeStarts: string;
  timeEnds: string;
  timeCreated: string;
  address?: {
    address1: string;
    address2: string;
    postcode: string;
    w3w: string;
  };
  salon: {
    isMobile: boolean;
    name: string;
    rating: string;
    address: {
      address1: string;
      address2: string;
      postcode: string;
    };
    images: string[];
  };
  therapist: {
    urn: string;
    name: string;
    logoImage: string;
    rating: {
      raw: number;
      display: string;
    };
  };
  bookingTreatments: IApiBookingTreatment[];
  chatUrn: string;
  isChatAllowed: boolean;
  status: string;
  canRate?: boolean;
  total: number;
  subTotal: number;
  bookingFee: number;
  credits?: number;
  promoDiscount?: number;
  promoCode?: string;
}

export interface IBooking {
  completed: IApiBooking[];
  upcoming: IApiBooking[];
}

export async function fetchBooking(bookingUrn: string): Promise<IApiBooking> {
  const response = await getHandler<IApiBooking>(
    `/customer/booking/${bookingUrn}`,
    true,
  );

  if (response.error) {
    throw new ApiError(response.error);
  }

  return response.result;
}

// tslint:disable-next-line: completed-docs
export async function saveRating(
  bookingUrn: string,
  rate: number,
  comment: string,
): Promise<void> {
  const body = JSON.stringify({
    bookingUrn,
    comment,
    rate,
  });

  await postHandler("/customer/rate/booking", body, true);

  return;
}
