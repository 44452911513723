/**
 * Navigation bar with logo and items
 */
import * as React from "react";
import styled from "styled-components";

import { NavItem, TopBar } from "../../../components/atoms";
import { Container } from "../../../components/grid";
import { NavSub } from "../../../components/molecules";
import { TopLogo } from "../../../components/molecules/top-logo"; // import directly to prevent cycling dependency
import { ICategory } from "../../../services/api/category-list";
import { ILoginUser } from "../../../services/api/customer";
import { IMenuItems, menuData } from "../../../static/menu";

import { NavigationItem, NavigationItems } from "./components";

export interface INavUserProps {
  isLoggedIn: boolean;
  user?: ILoginUser;
  specialLink?: string;
}

export interface IProps extends INavUserProps {
  categories: ICategory[];
}

const NavigationContainer = styled(Container)`
  @media screen and (max-width: 1200px) {
    padding: 0 10px;
  }

  @media screen and (max-width: 992px) {
    padding: 0 5%;
  }
`;

const TopLogoNavigation = styled(TopLogo)`
  @media screen and (max-width: 992px) {
    position: absolute;
    top: 15px;
  }
`;

const TopBarNavigation = styled(TopBar)`
  @media screen and (max-width: 992px) {
    flex-direction: column;
    padding: 0;
  }
`;

const Navigation: React.FC<IProps> = (props) => {
  // show items from array of stored menu items
  const menuItems = menuData.map((category, index) => {
    switch (category.id) {
      case "treatments": {
        return (
          <NavigationItem key={index}>
            <NavSub top="49" to={category.link} title="Treatments">
              {props.categories.map((category) => (
                <NavItem
                  key={category.code}
                  to={`/mobile-beauty/${category.code.toLowerCase()}`}
                  type="block"
                >
                  {category.name}
                </NavItem>
              ))}
            </NavSub>
          </NavigationItem>
        );
      }
      case "corporate": {
        return (
          <NavigationItem key={index}>
            <NavSub top="49" to={category.link} title="Corporate">
              <NavItem key="events" to="/events" type="block">
                Events
              </NavItem>
              <NavItem key="employee-wellness" to="/corporate" type="block">
                Employee Wellness
              </NavItem>
            </NavSub>
          </NavigationItem>
        );
      }
      case "switzerland": {
        return (
          <NavigationItem key={index}>
            <NavSub top="49" to={category.link} title="Switzerland">
              <NavItem key="zurich" to="/switzerland" type="block">
                Zurich
              </NavItem>
              <NavItem
                key="st-moritz"
                href="mailto:concierge@ruuby.com"
                type="block"
              >
                St. Moritz
              </NavItem>
              <NavItem
                key="geneva"
                href="https://share.hsforms.com/1z_d4avrcRdmNlXkPQaBvcQ3scj5"
                type="block"
                target="_blank"
              >
                Geneva
              </NavItem>
            </NavSub>
          </NavigationItem>
        );
      }
      default: {
        return (
          <NavigationItem key={index}>
            {setCategoryItem(category, props)}
          </NavigationItem>
        );
      }
    }
  });

  const NavigationBar = () => {
    return (
      <TopBarNavigation top="50" as="nav">
        <NavigationContainer limitedWidth noPadding>
          <TopLogoNavigation />
          <NavigationItems>{menuItems}</NavigationItems>
        </NavigationContainer>
      </TopBarNavigation>
    );
  };

  return NavigationBar();
};

/**
 * Set and return main navigation link for menu
 */
export function setCategoryItem(
  category: IMenuItems,
  props: INavUserProps,
): React.ReactNode {
  switch (category.id) {
    case "magazine":
    case "store":
      return (
        <NavItem href={category.link} type="link" target="_blank">
          {category.title}
        </NavItem>
      );
    case "special":
      return (
        <NavItem href={props.specialLink} type="link" target="_blank">
          {category.title}
        </NavItem>
      );
    case "free":
      return <NavItem to={category.link}>{category.title}</NavItem>;

    case "login":
      return props.isLoggedIn && props.user ? (
        <NavItem to="/account">{"Profile"}</NavItem>
      ) : (
        <NavItem to={category.link}>{category.title}</NavItem>
      );
    case "treatments":
    case "switzerland":
      return (
        <div>
          {category.title} <span>{">"}</span>
        </div>
      );
    default:
      return <NavItem to={category.link}>{category.title}</NavItem>;
  }
}

export function setCategorySubItem(category: IMenuItems): React.ReactNode {
  switch (category.id) {
    case "geneva":
      return (
        <NavItem href={category.link} type="block" target="_blank">
          {category.title}
        </NavItem>
      );
    case "st-moritz":
      return (
        <NavItem href={category.link} type="block">
          {category.title}
        </NavItem>
      );
    default:
      return <NavItem to={category.link}>{category.title}</NavItem>;
  }
}

export { Navigation };
