import { useCallback, useState } from "react";
import {
  ApiError,
  Currency,
  useCustomerLazyQuery,
} from "../../services/api/types/graphql";

interface CustomerCredit {
  remainingAmount: number;
  currency: Currency;
}

interface CustomerDetails {
  urn: string;
  firstName: string;
  lastName: string;
  email?: string;
  phone?: string;
  credits: CustomerCredit[];
  referralCode?: {
    amount?: number;
    currency?: Currency;
    code?: string;
  };
}

type UseCustomerDetailsReturn = {
  customerDetails: CustomerDetails | null;
  isLoading: boolean;
  error: ApiError | null;
  fetchCustomer: () => Promise<void>;
};

export const useCustomerDetails = (urn: string): UseCustomerDetailsReturn => {
  const [customerDetails, setCustomerDetails] =
    useState<CustomerDetails | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const [loadCustomer, { loading }] = useCustomerLazyQuery({
    onCompleted: ({ customer: data }) => {
      switch (data.__typename) {
        case "Customer":
          setCustomerDetails({
            urn: data.urn,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email ?? undefined,
            phone: data.phone ?? undefined,
            referralCode: {
              code: data.referralCode?.code,
              currency: data.referralCode?.currency,
              amount: data.referralCode?.amount,
            },
            credits:
              data.credits?.flatMap((creditGroup) =>
                creditGroup.credits.map((c) => ({
                  urn: c.urn,
                  remainingAmount: c.remainingAmount,
                  currency: creditGroup.currency ?? "",
                })),
              ) ?? [],
          });
          break;

        case "RuubyGraphError":
          setError(data.error);
          break;
      }
    },
    onError: () => {
      setError(ApiError.GENERAL_ERROR);
    },
    errorPolicy: "all",
  });

  const fetchCustomer = useCallback(async () => {
    await loadCustomer({ variables: { urn } });
  }, [loadCustomer, urn]);

  return {
    customerDetails,
    isLoading: loading,
    error,
    fetchCustomer,
  };
};
