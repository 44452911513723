import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import styled from "styled-components";
import { device } from "../../../../utils";
import { Button, Heading1, Heading2, Paragraph } from "../../atoms";

import { useDispatch, useSelector } from "react-redux";
import { useCustomerDetails } from "../../../../hooks/use-customer-details";
import { removeAuthToken } from "../../../../services/token-storage";
import { IRootState } from "../../../../store";
import { actionCreators } from "../../../../store/user/actions";
import { AppStore } from "../../../molecules";
import AccountImageMobile from "./profile-mobile.webp";
import AccountImageDesktop from "./profile-desktop.webp";
import AccountQRCode from "./profile-qr-code.webp";
import { formatPriceByCurrency } from "../../../../utils/format-price-by-currency";
import {
  Country,
  Currency,
  LanguageCode,
} from "../../../../services/api/types/graphql";
import { IconPhone } from "../../../atoms";
import { fetchAppConfiguration } from "../../../../store/configuration/actions";
import { config } from "../../../../config";
import { IconMail } from "../../atoms/icons/mail";
import { IconDebit } from "../../atoms/icons/debit";
import { IconLogout } from "../../atoms/icons/logout";

export enum BookingAction {
  cancel = "cancel",
  rate = "rate",
}

const OuterContainer = styled.div`
  margin: auto;
  width: 400px;
  max-width: 100%;
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    width: 745px;
  }

  @media ${device.laptop} {
    width: 920px;
  }

  @media ${device.desktop} {
    margin-top: 0;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  order: 1;

  @media ${device.desktop} {
    order: -1;
  }
`;

const LogoutButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  background-color: ${({ theme }) => theme.colours.background.white};
  color: ${({ theme }) => theme.colours.text.main};
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  font-size: 16px;
  text-transform: none;
  line-height: 30px;

  width: 100%;

  svg {
    fill: currentColor;
    width: 18px;
    height: 18px;
  }

  @media ${device.desktop} {
    width: 150px;
  }
`;

const AccountWrapper = styled("div")`
  display: flex;
  width: 400px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  flex-shrink: 0;
  margin: auto;
  margin-bottom: 20px;
  margin-top: 37px;
  border-radius: 7px;
  overflow: hidden;
  flex-direction: column;
  max-width: 100%;

  @media ${device.tablet} {
    flex-direction: row;
    gap: 0;
    width: 745px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.13);
    margin-bottom: 30px;
  }

  @media ${device.laptop} {
    width: 920px;
    height: 589px;
  }

  @media ${device.desktop} {
    margin-top: 0;
  }
`;

const ImageSection = styled("div")`
  width: 400px;
  height: 240px;
  border-radius: 8px;
  align-content: center;
  align-items: center;
  background: ${({ theme }) =>
    `url(${AccountImageMobile}) ${theme.colours.faintGrey} no-repeat center center`};
  background-size: cover;

  @media ${device.tablet} {
    border-radius: 0;
    max-width: 445px;
    min-width: 50%;
    flex-shrink: 0;
    align-self: stretch;
    width: unset;
    height: unset;

    background: ${({ theme }) =>
      `url(${AccountImageDesktop}) ${theme.colours.faintGrey} center center no-repeat`};
    background-size: cover;
  }
`;

const Content = styled("div")`
  display: flex;
  flex-direction: column;
  padding: 20px 26px;
  align-items: flex-start;
  justify-content: center;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  background-color: ${({ theme }) => theme.colours.background.white};
  border-radius: 8px;

  @media ${device.tablet} {
    padding: 40px 20px;
    border-radius: 0;
  }

  @media ${device.laptop} {
    padding: 60px 48px;
    max-width: 475px;
  }
`;

const MyProfileHeading = styled(Heading1)`
  margin-top: ${({ theme }) => theme.spacing.size16};
  font-size: ${({ theme }) => theme.type.sizes.size30};
  min-height: 40px;

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size36};
  }
`;

const UserNameHeading = styled(Heading2)`
  font-family: ${({ theme }) => theme.type.fonts.heading};
  min-height: 40px;
  font-size: ${({ theme }) => theme.type.sizes.size24};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size26};
  }
`;

const UserDetail = styled(Paragraph)`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 4px 0;
  padding: 0 20px;
  font-size: ${({ theme }) => theme.type.sizes.size16};

  @media ${device.tablet} {
    padding: 0 0px;
  }

  svg {
    margin-right: 10px;
    min-width: 20px;
  }
`;

// const GiftCardLink = styled("a")`
//   font-style: italic;
//   color: ${({ theme }) => theme.colours.text.main};
//   cursor: pointer;
//   margin: 4px 0;
//   padding: 0 48px;

//   text-decoration: underline;
// `;

const AppDownloadHeading = styled(Heading2)`
  margin: 20px 0 10px 0;
  font-size: ${({ theme }) => theme.type.sizes.size24};
  font-family: ${({ theme }) => theme.type.fonts.heading};

  @media ${device.tablet} {
    font-size: ${({ theme }) => theme.type.sizes.size30};
  }
`;

const AppDownloadParagraph = styled(Paragraph)`
  margin: 8px 0;
  font-size: ${({ theme }) => theme.type.sizes.size16};
`;

const QrAndButtons = styled("div")`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 16px;
`;

const QrCodeImage = styled.img`
  width: 88px;
  height: 88px;
  flex-shrink: 0;
  display: none;

  @media ${device.tablet} {
    display: block;
  }
`;

const ReferralBannerWrapper = styled("div")`
  order: 2;
`;

const ReferralBanner = styled("div")`
  width: 100%;
  max-width: 920px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colours.darkGrey};
  color: ${({ theme }) => theme.colours.white};
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
  }
`;

const ReferralBannerSkeleton = styled(Skeleton)`
  width: 100%;
  height: 150px;
  max-width: 920px;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    height: 110px;
  }
`;

const ReferralTextContainer = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    align-items: flex-start;
  }
`;

const ReferralHeading = styled(Heading2)`
  font-size: ${({ theme }) => theme.type.sizes.size20};
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colours.white};
`;

const ReferralParagraph = styled(Paragraph)`
  font-size: ${({ theme }) => theme.type.sizes.size14};
  margin: 0 0;
  padding: 0;
  color: ${({ theme }) => theme.colours.white};
`;

const ReferralButton = styled(Button)`
  background-color: ${({ theme }) => theme.colours.white};
  color: ${({ theme }) => theme.colours.text.main};
  border-radius: 20px;
  font-weight: bold;
  margin-top: 16px;

  @media ${device.tablet} {
    margin-top: 0;
  }
`;

export const AccountContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const urn = useSelector<IRootState>((state) => state?.userState?.user?.urn);

  const [isCopied, setIsCopied] = useState(false);

  const { referralReward } = useSelector<
    IRootState,
    { referralReward: string | undefined }
  >((state) => {
    return {
      referralReward: state?.configurationState?.configuration?.REFERRAL_REWARD,
    };
  });

  useEffect(() => {
    dispatch(fetchAppConfiguration());
  }, [dispatch]);

  useEffect(() => {
    if (!urn) {
      history.push("/");
    }
  }, [urn, history]);

  const { customerDetails, fetchCustomer, isLoading } = useCustomerDetails(
    urn as string,
  );

  const isMobile = /Mobi|Android/i.test(navigator.userAgent);
  const isShareSupported = isMobile && !!navigator.share;

  useEffect(() => {
    fetchCustomer();
  }, [fetchCustomer]);

  const handleLogout = useCallback(() => {
    dispatch(actionCreators.logoutUser());
    removeAuthToken();
    history.push("/");
  }, [dispatch]);

  const handleReferralClick = useCallback(async () => {
    if (!customerDetails || !customerDetails.referralCode) return;

    const text = `Hey, I think you’ll love Ruuby’s five-star at home beauty treatments. Here’s a code for £${customerDetails.referralCode.amount} off your first Ruuby booking to enter at checkout: '${customerDetails.referralCode.code}' Enjoy!`;

    if (isShareSupported) {
      try {
        await navigator.share({
          title: "Join Ruuby with my referral code",
          text,
        });
      } catch (err) {
        console.error("Share failed:", err);
      }
    } else {
      try {
        await navigator.clipboard.writeText(text);
        setIsCopied(true);
      } catch (err) {
        console.error("Copy failed:", err);
      }
    }
  }, [customerDetails]);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setIsCopied(false);
      }, 2000);
    }
  }, [isCopied]);

  return (
    <>
      <OuterContainer>
        <ButtonRow>
          <LogoutButton onClick={handleLogout}>
            <IconLogout />
            Log out
          </LogoutButton>
        </ButtonRow>
        <AccountWrapper>
          <ImageSection />
          <Content>
            <MyProfileHeading>My Profile</MyProfileHeading>
            <UserNameHeading>
              {isLoading ? (
                <Skeleton height={40} width={200} />
              ) : (
                customerDetails?.firstName + " " + customerDetails?.lastName
              )}
            </UserNameHeading>
            <UserDetail>
              <IconMail />
              {isLoading ? (
                <Skeleton height={20} width={200} />
              ) : (
                customerDetails?.email ?? ""
              )}
            </UserDetail>

            <UserDetail>
              <IconPhone />
              {isLoading ? (
                <Skeleton height={20} width={200} />
              ) : (
                customerDetails?.phone ?? ""
              )}
            </UserDetail>

            {isLoading ? (
              <UserDetail>
                <IconDebit />
                <Skeleton height={20} width={300} />
              </UserDetail>
            ) : (
              (() => {
                const defaultCredits = {
                  [Currency.GBP]: 0,
                  [Currency.CHF]: 0,
                };

                customerDetails?.credits?.forEach((credit) => {
                  if (credit.currency in defaultCredits) {
                    defaultCredits[credit.currency] = credit.remainingAmount;
                  }
                });

                const creditsString = [Currency.GBP, Currency.CHF]
                  .filter((currency) => defaultCredits[currency] > 0)
                  .map((currency) =>
                    formatPriceByCurrency(
                      defaultCredits[currency],
                      currency,
                      Country.GB,
                      LanguageCode.EN,
                    ),
                  )
                  .join(" / ");

                if (creditsString.length > 0) {
                  return (
                    <UserDetail>
                      <IconDebit />
                      Account Credit: {creditsString}
                    </UserDetail>
                  );
                }

                return <UserDetail>&nbsp;</UserDetail>;
              })()
            )}

            {/* <GiftCardLink>Redeem a gift card</GiftCardLink> */}

            <AppDownloadHeading>Download the app</AppDownloadHeading>
            <AppDownloadParagraph large>
              Get live booking updates, access to exclusive offers, and chat
              directly to your Ruuby professional.
            </AppDownloadParagraph>

            <QrAndButtons>
              <QrCodeImage src={AccountQRCode} alt="QR code" />
              <AppStore adjustLink={config.adjustAnalyticsUrl} />
            </QrAndButtons>
          </Content>
        </AccountWrapper>
        <ReferralBannerWrapper>
          {isLoading ? (
            <ReferralBannerSkeleton />
          ) : customerDetails?.referralCode?.code ? (
            <ReferralBanner>
              <ReferralTextContainer>
                <ReferralHeading>
                  GIVE £{referralReward}, GET £{referralReward}
                </ReferralHeading>
                <ReferralParagraph>
                  Refer a friend to Ruuby and you'll both get £{referralReward}{" "}
                  credit after their first booking.
                </ReferralParagraph>
                <ReferralParagraph>
                  Your unique referral code:{" "}
                  <strong>{customerDetails.referralCode.code}</strong>
                </ReferralParagraph>
              </ReferralTextContainer>
              <ReferralButton size="small" onClick={handleReferralClick}>
                {isShareSupported ? "REFER NOW" : isCopied ? "COPIED" : "COPY"}
              </ReferralButton>
            </ReferralBanner>
          ) : null}
        </ReferralBannerWrapper>
      </OuterContainer>
    </>
  );
};
