import * as React from "react";
import Helmet from "react-helmet";

import { Container } from "../../../components/grid";
import { CommonTemplate } from "../../../components/templates/common";
import { AccountContainer } from "../../themed-v2/pages/account";
import { metaData } from "../../../static/meta";

const AccountPage: React.FC = () => {
  return (
    <CommonTemplate>
      <Helmet>
        <title>{metaData.account.title}</title>
        <meta name="description" content={metaData.account.description} />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Container limitedWidth offsetPaddings>
        <AccountContainer />
      </Container>
    </CommonTemplate>
  );
};

export { AccountPage };
